// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-resource-post-js": () => import("D:\\a\\1\\s\\src\\templates\\resource-post.js" /* webpackChunkName: "component---src-templates-resource-post-js" */),
  "component---src-pages-404-js": () => import("D:\\a\\1\\s\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-thanks-js": () => import("D:\\a\\1\\s\\src\\pages\\contact-thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-contact-js": () => import("D:\\a\\1\\s\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("D:\\a\\1\\s\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("D:\\a\\1\\s\\src\\pages\\portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-resources-js": () => import("D:\\a\\1\\s\\src\\pages\\resources.js" /* webpackChunkName: "component---src-pages-resources-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "D:\\a\\1\\s\\.cache\\data.json")

